import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA CUTTING HE------
    getHistoriList(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/data/cutting-he", { params: credentials })
    },
    getHistoriDetail(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/data/cutting-he/single", { params: credentials })
    },

    getTarget(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/data/cutting-he/target", { params: credentials })
    },
    getTargetDetail(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/data/cutting-he/target/single", { params: credentials })
    },

    getCuttingHESE() {
        ApiService.setHeader();
        return ApiService.get("v2/data/cutting-he/se")
    },
    getCuttingHESEPerminggu(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/data/cutting-he/se-perminggu", { params: credentials })
    },
    getCuttingHEEdit(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/data/cutting-he/edit", { params: credentials })
    },

    addCuttingHE(credentials) {
        ApiService.setHeader();
        return ApiService.post("v2/data/cutting-he", credentials)
    },
    updateCuttingHE(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/data/cutting-he", credentials)
    },
    deleteCuttingHE(credentials) {
        ApiService.setHeader();
        return ApiService.delete("v2/data/cutting-he", { data: credentials })
    },
}